import Service from "../Service";

const resource = "accounting/";

export default {
    saveIMPISA(obj, requestID) {
        return Service.post(resource + "saveIMPISA", obj, {
            params: { requestID: requestID },
        });
    },

    saveMatriz(obj, requestID) {
        return Service.post(resource + "saveMatriz", obj, {
            params: { requestID: requestID },
        });
    },

    generatedAccounting(obj, requestID) {
        return Service.post(resource + "generatedAccounting", obj, {
            params: { requestID: requestID },
        });
    },

    migratedAccounting(obj, requestID) {
        return Service.post(resource + "migratedAccounting", obj, {
            params: { requestID: requestID },
        });
    },


    restartCorrelative(CacBussinesnName, requestID) {
        return Service.post(resource + "restartCorrelative", {}, {
            params: { CacBussinesnName: CacBussinesnName, requestID: requestID },
        });
    },


    generatedReportSales(obj, requestID) {
        return Service.post(resource + "generatedReportSales", obj, {
            params: { requestID: requestID },
        });
    },
    saveCapital(obj, requestID) {
        return Service.post(resource + "capitalsave", obj, {
            params: { requestID: requestID },
        });
    },
   


};