<template>
  <div>
    <v-container class="px-0">
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Consulta de Clientes EVOLTA"
            class="tran"
          ></s-toolbar>
          <v-card>
            <v-row style="margin-top: -40px">
              <v-col cols="12">
                <v-card class="mx-auto my-1" width="90%">
                  <v-row
                    justify="center"
                    style="margin-top: 4px; margin-left: 10px; margin: auto"
                  >
                    <v-col
                      cols="12"
                      lg="12"
                      md="12"
                      v-if="type == 1 || type == 2"
                    >
                      <s-import-excel
                        :accept="'.xlsx'"
                        @collection="collection($event)"
                        @loaded="loaded($event)"
                        :headers="headersCapital"
                      >
                      </s-import-excel>
                    </v-col>
                  </v-row>

                  <v-divider class="mx-4"></v-divider>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al cargar data <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _sAccounting from "@/services/Accounting/ConChargeMasive.js";
export default {
  components: {},
  data() {
    return {
      processing: false,
      messageProcessing: "",
      headersCapital: [
       
        {
          text: "Serie",
          value: "SERIE",
          column: "SERIE",
        },
        {
          text: "Correlativo",
          value: "CORRELATIVO",
          column: "CORRELATIVO",
        },
        {
          text: "Monto",
          value: "MONTO",
          column: "MONTO",
        },
     
      ],
      type: 1,
    };
  },

  created() {},

  watch: {},

  methods: {
    collection(collection) {
      var isValid = true;

      this.$fun.alert("Seguro de Cargar Datos?", "question").then((r) => {
        if (r.value) {
          collection.map((item) => {
            item.UsrCreateID = this.$fun.getUserID();
            item.SecStatus = 1;
            item.Destiny = this.type;

            if (this.type !== 3) {
              if (
                item.CccID == "" ||
                item.CccID == null ||
                item.CccID == undefined
              ) {
                this.$fun.alert(
                  "Error, unos de los registros, no tiene Nro. de CccID. Por favor verifique.",
                  "error"
                );
                isValid = false;
                return;
              }
            }
          });
          console.log({collection});

          this.processing = true;
          _sAccounting.saveCapital(collection, this.$fun.getUserID()).then(
            (r) => {
              if (r.status == 200) {
                this.processing = false;
                this.$fun.alert("Data cargada Correctamente", "success");
              }
            },
            (e) => {
              this.messageProcessing = e.response.data.Message;
              this.processing = true;
            }
          );
        }
      });
    },

    inputDestiny(val) {
      if (val !== undefined) {
        this.type = val;
      }
    },
    loaded(table) {},
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
